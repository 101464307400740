/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-key */
import { Crown, DownloadsIcon, TickIcon } from '@atoms/icons';
import { useDashboard, useLeaderBoard } from '@hc/store';
import { queryClient } from '@hc/utils';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import leaderboardEmptyState from '../../../assets/emptyLeaderboard.svg';
import { Loader } from '../../../atoms';
import { LeaderBoardCard } from '../leaderBoardCard';
import { LeaderBoardSkeletonLoader } from '../leaderBoardSkeletonLoader';
import { LeaderTitleCard } from '../leaderTitleCard';
import { leaderBoard_style } from './style';

export function LeaderBoard(props) {
  const { className = '', ...rest } = props;
  const {
    leaderboardState,
    getLeaderBoard,
    updateLeaderboardStateStartEndDate,
    leaderBoardLoading,
    loading,
  } = useLeaderBoard((state) => ({
    leaderBoardLoading: state.leaderBoardLoading,
    leaderboardState: state.leaderboardState,
    getLeaderBoard: state.getLeaderBoard,
    updateLeaderboardStateStartEndDate:
      state.updateLeaderboardStateStartEndDate,
    endOfMonth: state.endOfMonth,
    loading: state.loading,
  }));
  const { leaderboard, startDate, endDate } = leaderboardState;

  // Store Data
  const { getLeaderBoardDownload } = useDashboard((state) => ({
    getLeaderBoardDownload: state.getLeaderBoardDownload,
  }));

  const selection = [
    {
      value: 'over_all',
      label: 'Over All',
    },
    {
      value: 'this_week',
      label: 'This Week',
    },
    {
      value: 'this_month',
      label: 'This Month',
    },
  ];
  const [open, setOpen] = React.useState(selection[0]);

  const handleChange = async (value) => {
    if (value?.value === 'over_all') {
      updateLeaderboardStateStartEndDate('', '');
    } else if (value?.value === 'this_week') {
      updateLeaderboardStateStartEndDate(
        startOfWeek(new Date()) ?? '',
        endOfWeek(new Date()) ?? ''
      );
    } else if (value?.value === 'this_month') {
      updateLeaderboardStateStartEndDate(
        startOfMonth(new Date()) ?? '',
        endOfMonth(new Date()) ?? ''
      );
    }
    // api call{
    queryClient.invalidateQueries({
      queryKey: ['leaderBoard'],
    });
    getLeaderBoard();
    setOpen(value);
  };
  // const downloadURI = (uri) => {
  //   debugger;
  //   console.log(uri, 'uri');
  //   const link = document.createElement('a');
  //   link.href = uri;
  //   document.body.appendChild(link);
  //   link.click();
  // link.click((e) => e.preventDefault());
  // link.addEventListener('click', (event) => event.preventDefault());
  //   link.remove();
  // };

  const downloadButtonClick = async () => {
    const response = await getLeaderBoardDownload('', '');
    // window.open(response);
    history.pushState({}, '', response);
    // window.location.href = response;
    // downloadURI(response);
  };
  // let response;
  // const DownloadUrl = async () => {
  //   response = await getLeaderBoardDownload('', '');
  //   console.log(response);
  // };
  // console.log(response);

  useEffect(() => {
    getLeaderBoard();
    // DownloadUrl();
  }, []);

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ backgroundColor: '#fff', p: 2, height: '100vh' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Box sx={leaderBoard_style.headerSx}>
              <Typography sx={leaderBoard_style.titleSx}>
                Leaderboard
              </Typography>
              <Box sx={{ mx: 0.5 }}>
                <Crown />
              </Box>
            </Box>

            {startDate && endDate ? (
              <Typography sx={leaderBoard_style.dateSx}>
                {`${moment(startDate).format('MMM Do YY')}`} -
                {`${moment(endDate).format('MMM Do YY')}`}
              </Typography>
            ) : null}
          </Box>

          <Box>
            <Select
              id="dropdown2"
              value={open?.value}
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: '0px 0px 4px 2px #E3E3E3',
                  },
                },
              }}
              IconComponent={BiChevronDown}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSvgIcon-root': {
                  display: 'none',
                },
                fontSize: '12px',
              }}
            >
              {selection?.map((val, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleChange(val)}
                  value={val?.value}
                  sx={leaderBoard_style.menuListSx}
                >
                  {open?.value === val?.value ? <TickIcon /> : null}
                  &nbsp;{val?.label}
                </MenuItem>
              ))}
            </Select>
            &nbsp;
            {/* <a href={download_url} download style={{ textDecoration: 'none' }}>
              <DownloadsIcon onClick={() => downloadButtonClick()} />
            </a>
            <Box> */}
            {leaderBoardLoading ? (
              <Loader size={17} rootStyle={{ p: 0 }} />
            ) : (
              <a
                href=""
                download
                style={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                <DownloadsIcon onClick={downloadButtonClick} />
              </a>
            )}
            {/* </Box> */}
          </Box>
        </Box>
        <LeaderTitleCard />

        {loading && (
          <Box sx={{ overflow: 'scroll', height: '80vh' }}>
            {Array(10)
              .fill(1)
              .map((i) => (
                <Box mt={1.5} key={i}>
                  <LeaderBoardSkeletonLoader />
                </Box>
              ))}
          </Box>
        )}

        {!loading && (
          <Box>
            {leaderboard?.length > 0 ? (
              <Box sx={leaderBoard_style.cardListSx}>
                {leaderboard.map((val, index) => (
                  <Box key={index} sx={{ mt: 1.5 }}>
                    <LeaderBoardCard
                      mindValue={
                        Array.isArray(val?.mbf) && val?.mbf?.length > 0
                          ? Math.round(
                              val?.mbf?.filter(
                                (val) => val?.type === 'Mind'
                              )?.[0]?.percentage
                            ) ?? 0
                          : 0
                      }
                      bodyValue={
                        Array.isArray(val?.mbf) && val?.mbf?.length > 0
                          ? Math.round(
                              val?.mbf?.filter(
                                (val) => val?.type === 'Body'
                              )?.[0]?.percentage
                            ) ?? 0
                          : 0
                      }
                      foodValue={
                        Array.isArray(val?.mbf) && val?.mbf?.length > 0
                          ? Math.round(
                              val?.mbf?.filter(
                                (val) => val?.type === 'Food'
                              )?.[0]?.percentage
                            ) ?? 0
                          : 0
                      }
                      leaderName={val?.first_name}
                      rankList={index + 1}
                      running={val?.assinged_activity}
                      energyPercentage={val?.total ? Math.round(val?.total) : 0}
                      index={index}
                      src={val?.profile_pic}
                      leaderBoardRankList={{
                        bgcolor: index < 3 ? '#FCF8E8' : '#fff',
                        borderColor: index < 3 ? '#FBD74A' : '#EEEEEE',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={3}
                >
                  <img
                    style={{ height: '100%' }}
                    src={leaderboardEmptyState}
                    alt=""
                  />
                </Box>
                <Box textAlign="center" mt={2}>
                  <Typography fontSize={16} fontWeight={600}>
                    No Notifications Yet
                  </Typography>
                </Box>
                <Box textAlign="center" mt={1.5} mb={2}>
                  <Typography fontSize={12} color="#888888">
                    Get Mind Body Food subscription for your team soon!
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

LeaderBoard.propTypes = {
  className: PropTypes.string,
};
