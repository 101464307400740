import { Button, DialogModal } from '@atoms';
import { UploadDialogTable } from '@components';
import { useMember } from '@hc/store';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Loader } from '../../../atoms';
import { UploadMapping } from '../uploadMapping';
import { upload_dialog_style } from './style';

export function UploadDialog(props) {
  const {
    className = '',
    title = 'Upload Member List',
    rootStyle = {},
    isModalOpen,
    loading,
    onCheckClick = () => {},
    Onchange = () => {},
    excelBoolVal,
    handleClose = () => {},
    handleUpload = () => {},
    handleSelect = () => {},
    contract = '',
    checkValid = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...upload_dialog_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title={title}
        titleStyle={upload_dialog_style.titleSx}
        dialogRootStyle={upload_dialog_style.dialogSx}
        content={
          <Box>
            {loading && (
              <Box height="100vh" justifyContent="center" display="flex">
                <Box alignSelf="center">
                  <Loader />
                </Box>
              </Box>
            )}
            <Box sx={loading ? { display: 'none' } : ''}>
              <DialogContent
                excelBoolVal={excelBoolVal}
                onCheckClick={onCheckClick}
                Onchange={Onchange}
                handleUpload={handleUpload}
                checkValid={checkValid}
                handleClose={handleClose}
                handleSelect={handleSelect}
                contract={contract}
              />
            </Box>
          </Box>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleClose}
      />
    </Box>
  );
}

function DialogContent(props) {
  const {
    className = '',
    rootStyle = {},
    Onchange = () => {},
    handleUpload = () => {},
    checkValid = '',
    handleClose = () => {},
    handleSelect = () => {},
    excelBoolVal = [],
    contract = '',
    ...rest
  } = props;

  // Store Data - Members Data
  const { loading } = useMember((state) => ({
    loading: state.loading,
  }));

  return (
    <Box
      sx={{ ...upload_dialog_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box pb={1.5}>
        <Typography fontSize={16} fontWeight="medium">
          Uploaded table
        </Typography>
      </Box>
      <Box height={360} sx={{ overflowY: 'auto' }}>
        <UploadDialogTable />
      </Box>
      <Box pt={2.5}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <UploadMapping
              value={excelBoolVal}
              Onchange={Onchange}
              handleSelect={handleSelect}
              checkValid={checkValid}
              contract={contract}
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid py={4} container columnSpacing={2}>
              <Grid item xs={4.5}>
                <Button
                  buttonStyle={upload_dialog_style.buttonSx}
                  variant="contained"
                  onClick={handleUpload}
                  loading={loading}
                >
                  Upload Table
                </Button>
              </Grid>
              <Grid item xs={4.5}>
                <Button
                  onClick={handleClose}
                  buttonStyle={upload_dialog_style.buttonSx}
                  variant="outlined"
                  disabled={loading === true}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

DialogContent.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  Onchange: PropTypes.func,
  handleUpload: PropTypes.func,
  checkValid: PropTypes.string,
  handleClose: PropTypes.func,
  handleSelect: PropTypes.func,
  excelBoolVal: PropTypes.array,
  contract: PropTypes.string,
};

UploadDialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  rootStyle: PropTypes.string,
  tableHeader: PropTypes.string,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  closeMemberModal: PropTypes.func,
  Onchange: PropTypes.func,
  excelBoolVal: PropTypes.bool,
  handleUpload: PropTypes.func,
  loading: PropTypes.bool,
  checkValid: PropTypes.string,
  onCheckClick: PropTypes.func,
  handleSelect: PropTypes.func,
  contract: PropTypes.string,
};
