/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import { useGender, useMember, useOrgProfile } from '@hc/store';
import {
  Button,
  Drawer,
  Dropdown,
  Input,
  Label,
  MobileInput,
} from '@hc/ui/atoms';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { addMemberDrawer_style } from './style';

export function AddMemberDrawer(props) {
  const {
    className = '',
    addMemberDrawer,
    closeMemberDrawer = () => false,
    addMember = () => false,
    ...rest
  } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const { profileView } = useOrgProfile((state) => ({
    profileView: state.profileView,
  }));
  const {
    member,
    handleMemberFormChange,
    loading,
    getMemberCount,
    contract_Count,
  } = useMember((state) => ({
    member: state.member,
    handleMemberFormChange: state.handleMemberFormChange,
    loading: state.loading,
    getMemberCount: state.getMemberCount,
    contract_Count: state.contract_Count,
  }));

  const { genderData, getGenderDetails } = useGender((state) => ({
    genderData: state.genderData,
    getGenderDetails: state.getGenderDetails,
  }));

  const newgenderData = genderData.filter(
    (filter) => filter.label !== 'transgender'
  );

  const handleChange = (key, value) => {
    handleMemberFormChange(key, value);
  };

  useEffect(() => {
    getMemberCount(profileView?.id ?? tokenData.organization_id);
  }, []);

  useEffect(() => {
    getGenderDetails();
  }, []);

  // const newContract_Count = contract_Count.map((contract) => ({
  //   label: `${contract.contract_id} (${contract.remaining_member_count})`,
  //   value: contract.id,
  // }));

  let newContract_Count = [];
  for (const object of contract_Count) {
    if (object.remaining_member_count > 0) {
      let obj = {
        label: `${object.contract_id} (${object.remaining_member_count})`,
        value: object.id,
      };
      newContract_Count.push(obj);
    }
  }

  return (
    <Box className={`${className}`} {...rest}>
      <Box>
        {addMemberDrawer && (
          <Drawer
            show={addMemberDrawer}
            onCloseDrawer={closeMemberDrawer}
            anchor="right"
            isCloseIconRequired
            header="Members"
            headerStyle={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#101010',
              textTransform: 'capitalize',
            }}
            footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
            footer={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  loading={loading}
                  id="add_member"
                  buttonStyle={{
                    bgcolor: 'primary.main',
                    textTransform: 'capitalize',
                    color: '#fff',
                    borderRadius: '8px',
                    '&:hover': {
                      bgcolor: 'primary.main',
                    },
                  }}
                  onClick={addMember}
                >
                  Add Member
                </Button>
                <Button
                  disabled={loading === true ? true : false}
                  buttonStyle={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    bgcolor: '#fff',
                    '&:hover': {
                      bgcolor: 'primaryTints.200',
                    },
                  }}
                  onClick={closeMemberDrawer}
                >
                  Cancel
                </Button>
              </Box>
            }
          >
            <Box sx={addMemberDrawer_style.boxRootSx}>
              <Box sx={addMemberDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addMemberDrawer_style.labelSx}
                  htmlFor="name"
                  isRequired
                >
                  Name
                </Label>
                <Input
                  id="name"
                  textFieldStyle={addMemberDrawer_style.inputFieldSx}
                  onChange={(e) => {
                    handleChange('name', e.target.value, 20);
                  }}
                  isError={member?.error?.name?.length > 0}
                  errorMessage={member?.error?.name}
                  value={member?.name}
                  fullWidth
                />
              </Box>
              <Box sx={addMemberDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addMemberDrawer_style.labelSx}
                  htmlFor="email"
                  isRequired
                >
                  Email
                </Label>
                <Input
                  id="email"
                  value={member?.email_id}
                  onChange={(e) => {
                    handleChange('email_id', e.target.value, 30);
                  }}
                  isError={member?.error?.email_id}
                  errorMessage={member?.error?.email_id}
                  textFieldStyle={addMemberDrawer_style.inputFieldSx}
                />
              </Box>
              <Box sx={addMemberDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addMemberDrawer_style.labelSx}
                  htmlFor="mobileNumber"
                  isRequired
                >
                  Phone Number
                </Label>
                <MobileInput
                  id="mobileNumber"
                  mobileInputStyle={addMemberDrawer_style.inputFieldSx}
                  value={{
                    mobile_code: member?.country_code ?? '+91',
                    mobile: member?.mobile_no ?? '',
                  }}
                  helperText={member?.error?.mobile_no}
                  isError={member?.error?.mobile_no?.length > 0}
                  onChange={(value) => handleChange('mobileNumber', value, 10)}
                />
              </Box>
              <Box>
                <Label
                  rootStyle={addMemberDrawer_style.labelSx}
                  htmlFor="mobileNumber"
                  isRequired
                >
                  contract
                </Label>
                <Dropdown
                datatestid='contract'
                  value={member?.contract_id}
                  onChange={(e) => handleChange('contract_id', e.target.value)}
                  selectOption={newContract_Count}
                  isError={member?.error?.contract_id?.length > 0}
                  helperText={member?.error?.contract_id}
                />
              </Box>

              <Box sx={addMemberDrawer_style.inputGroupSx}>
                <Box sx={addMemberDrawer_style.genderSx}>
                  {newgenderData?.map((val, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...addMemberDrawer_style.rootchildrenSx,
                        ...(val?.value === member?.gender?.value
                          ? addMemberDrawer_style.selectAvailabilityButtonSx
                          : addMemberDrawer_style.unSelectAvailabilityButtonSx),
                      }}
                      id={`gender${index}`}
                      onClick={() => handleChange('gender', val)}
                    >
                      <Box
                        sx={{
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            p: 1.25,
                          }}
                        >
                          {val?.label}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

AddMemberDrawer.propTypes = {
  addMemberDrawer: PropTypes.bool,
  closeMemberDrawer: PropTypes.func,
  addMember: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
};
