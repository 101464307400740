import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { Box, IconButton, Typography, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useOrganizationOnboarding, useOrgProfile } from '@hc/store';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { login_content_card_style } from './style';

export function LoginContentCard() {
  const {
    user,
    signIn,
    loading,
    updateOnboarding,
    updateErrorOnboarding,
    updateStateOnBoarding,
  } = useOrganizationOnboarding(
    (state) => ({
      signIn: state.signIn,
      user: state.user,
      loading: state.loading,
      updateOnboarding: state.updateOnboarding,
      updateErrorOnboarding: state.updateErrorOnboarding,
      updateStateOnBoarding: state.updateStateOnBoarding,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { getProfileView } = useOrgProfile((state) => ({
    getProfileView: state.getProfileView,
  }));

  const navigate = useNavigate();
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  // Validation
  const isIamValideToSignIn = () => {
    let isValid = true;
    const error = user?.error;

    //  Checking email
    if (user?.email.length === 0) {
      isValid = false;
      error.email = 'Enter a valid email';
    } else if (user?.email.length < 10) {
      isValid = false;
      error.email = 'Enter a valid email';
    } else {
      error.email = '';
    }

    // Checking password
    if (user?.password.length === 0) {
      isValid = false;
      error.password = 'Enter password';
    } else {
      error.password = '';
    }
    updateErrorOnboarding(error);
    return isValid;
  };

  const onSignIn = async () => {
    if (isIamValideToSignIn()) {
      const error = user?.error;
      error.password = '';
      error.email = '';
      updateErrorOnboarding(error);
      // calling the signup api
      const response = await signIn(user);
      if (response?.status === 200) {
        getProfileView();
      }
    }
  };

  useEffect(() => {
    updateStateOnBoarding();
  }, []);

  const handleClick = () => {
    navigate('/forgotpassword');
  };

  return (
    <Box sx={login_content_card_style.CardcontentSx}>
      <Box sx={login_content_card_style.logowithtextSx}>
        <IconButton disableRipple>
          <HealthCirclesLogo rootStyle={login_content_card_style.hcLogoSx} />
        </IconButton>
        <Typography sx={login_content_card_style.companytypoSx}>
          Health Circles
        </Typography>
      </Box>

      <Typography sx={login_content_card_style.createPasswordSx}>
        Welcome
      </Typography>

      <Box>
        <Label htmlFor="emailInput">Email</Label>
        <Input
          value={user?.email ?? ''}
          id="email"
          isError={user?.error.email}
          helperText={user?.error.email}
          onChange={(value) => handleChange(value.target.value, 'email')}
        />
      </Box>
      <Box sx={login_content_card_style.inputGroupSx}>
        <Label htmlFor="password" isRequired>
          password
        </Label>
        <Input
          id="password"
          type={showpassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e.target.value, 'password')}
          isError={user?.error.password}
          errorMessage={user?.error.password}
          value={user?.password ?? ''}
          endAdornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => handleClickShowPassword()}
              edge="end"
            >
              {showpassword ? (
                <MdVisibilityOff
                  htmlColor="#848484"
                  sx={login_content_card_style.eyeSx}
                />
              ) : (
                <MdVisibility
                  htmlColor="#848484"
                  sx={login_content_card_style.eyeSx}
                />
              )}
            </IconButton>
          }
        />
        <Stack alignItems="end">
          <Typography
            variant="span"
            sx={login_content_card_style.forgotPasswordTextSx}
            onClick={handleClick}
          >
            {' '}
            Forgot Password?
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
        <Button
          fullWidth
          buttonStyle={login_content_card_style.buttonStyleSx}
          onClick={() => onSignIn()}
          loading={loading}
        >
          login
        </Button>
      </Box>
    </Box>
  );
}
