import { getDateFormatToString } from '@hc/dayjs';
import { useMember, useOrganization } from '@hc/store';
import { RefreshIcon, Table } from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import {
  Box,
  capitalize,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { Pagination } from '../../../backoffice/pagination';

export function MembersTable() {
  const {
    membersData,
    updateStateData,
    memberCount,
    options,
    getAllOrganizationMembers,
  } = useMember((state) => ({
    memberCount: state.memberCount,
    membersData: state.membersData,
    updateStateData: state.updateStateData,
    options: state.options,
    getAllOrganizationMembers: state.getAllOrganizationMembers,
  }));

  const { resendInvite } = useOrganization((state) => ({
    resendInvite: state.resendInvite,
  }));

  const statusColor = {
    1: '#25C460',
    2: '#fff',
    3: '#88888',
    4: '#E4BC62',
  };

  const resendInviteBtn = async (data) => {
    const obj = {
      user_id: data?.id ?? '',
      user_profile_id: data?.user_profile_id ?? '',
      role_id: data?.role_id ?? 0,
    };
    queryClient.invalidateQueries({
      queryKey: ['user-resend-invite'],
    });
    await resendInvite(obj);
  };

  const onLimitChange = async (limit, offset) => {
    updateStateData('limit', limit);
    updateStateData('offset', offset);
    queryClient.invalidateQueries({
      queryKey: ['organization_members/all'],
    });
    await getAllOrganizationMembers(
      options?.search,
      options?.limit,
      options?.offset
    );
  };

  const onOffsetchange = async (offset) => {
    updateStateData('offset', offset);
    queryClient.invalidateQueries({
      queryKey: ['organization_members/all'],
    });
    await getAllOrganizationMembers(
      options?.search,
      options?.limit,
      options?.offset
    );
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 160,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          {capitalize(params.value)}
        </Typography>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 210,
    },
    {
      field: 'membershipCode',
      headerName: 'Mem. Code',
      width: 170,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 180,
    },
    // {
    //   field: 'gender',
    //   headerName: 'Gender',
    //   width: 100,
    // },
    // {
    //   field: 'isActive',
    //   headerName: 'Unblock/Block',
    //   // type: 'number',
    //   width: 160,
    //   renderCell: (params) => (
    //     <Switch
    //       checked={params.row.is_active}
    //       onChange={(e) => attachDetachMembers(params.row, e.target.checked)}
    //     />
    //   ),
    // },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{
            color: statusColor[params.row.statusId],
            // backgroundColor: statusColor[params.row.statusId],
          }}
        >
          {params.row.status}
          {params.row.statusId === 4 && (
            <Tooltip title="Resend Invite">
              <IconButton
                color="secondary"
                onClick={() => resendInviteBtn(params.row)}
              >
                <RefreshIcon
                  rootStyle={{ color: statusColor[params.row.statusId] }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params?.row?.createdAt, 'lll'),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updatedAt, 'lll'),
    },
  ];

  return (
    <Box pb={8}>
      <Table
        rows={membersData}
        columns={columns}
        rootStyle={{ height: '80vh' }}
        emptyState
        customTableStyle={{
          '& .MuiDataGrid-virtualScrollerContent': {
            height: '80vh !important',
          },
          '& .MuiDataGrid-row': {
            borderBottom: '2px solid',
            borderColor: 'grey.400',
          },
          '& .MuiDataGrid-main': {
            border: '1px solid',
            borderColor: 'grey.400',
            bgcolor: '#FFFFFF',
            borderRadius: '6px',
          },
          '& .MuiDataGrid-footerContainer': {
            display: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#EDEFF2',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid',
            borderColor: 'grey.400',
          },
        }}
        hideFooter
        autoHeight={Object.keys(membersData ?? '').length > 0 ? false : true}
      />
      <Stack flexDirection="row" justifyContent="end" sx={{ width: '100%' }}>
        <Pagination
          totalCount={memberCount}
          onLimitChange={onLimitChange}
          onOffsetchange={onOffsetchange}
          offset={options?.offset}
          rowsPerPage={options?.limit}
        />
      </Stack>
    </Box>
  );
}
