/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
import { useGender, useMember } from '@hc/store';
import {
  Button,
  DialogModal,
  MembersDownloadIcon,
  MembersUploadIcon,
  SearchField,
  Table,
} from '@hc/ui/atoms';
import { UploadDialog } from '@hc/ui/components/organization';
import { queryClient } from '@hc/utils';
import { localStorageKeys } from '@hc/utils/constants';
import PropTypes from 'prop-types';
import {
  GetColumnData,
  parseJwt,
  schemaDataMapper,
} from '@hc/utils/helperFunctions';
import { Box, Grid, IconButton, Tab, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import toast from 'react-hot-toast';
import { members_top_container_Style } from './style';

export function MembersTopContainer(props) {
  const { handleClick = () => {} } = props;

  const inputRef = useRef();

  // Store Data - Members Data
  const {
    getAllOrganizationMembers,
    addExcelMappingMemberList,
    setExcelMappingMemberList,
    membersData,
    getExcelData,
    excelData,
    updateStateData,
    options,
    download_url,
    getExcelColumnData,
    handleMemberFormChange,
    loading,
  } = useMember((state) => ({
    membersData: state.membersData,
    handleMemberFormChange: state.handleMemberFormChange,
    loading: state.loading,
    updateStateData: state.updateStateData,
    getAllOrganizationMembers: state.getAllOrganizationMembers,
    options: state.options,
    addExcelMappingMemberList: state.addExcelMappingMemberList,
    setExcelMappingMemberList: state.setExcelMappingMemberList,
    getExcelData: state.getExcelData,
    excelData: state.excelData,
    download_url: state.download_url,
    getExcelColumnData: state.getExcelColumnData,
  }));

  const { contract_Count } = useMember((state) => ({
    contract_Count: state.contract_Count,
  }));

  // Store Data - Gender Data
  const { getGenderDetails } = useGender((state) => ({
    getGenderDetails: state.getGenderDetails,
  }));

  const mappingColumnData = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 160,
      isRequired: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 210,
      isRequired: true,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 160,
      isRequired: true,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      width: 160,
      isRequired: false,
    },
  ];

  // General Hooks

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectedModalOpen, setIsRejectedModalOpen] = useState(false);
  const [excelBoolVal, setexcelBoolVal] = useState(mappingColumnData);
  const [checkValid, setCheckValid] = useState('');
  const [contractdetail, setcontractdetail] = useState('');
  const [contract, setcontract] = useState('');
  const [rejectedData, setRejectedData] = useState([]);
  const [rejectedColumnData, setRejectedColumnData] = useState([]);
  const [downloadLink, setDownloadLink] = useState('');

  const handleSelect = (e) => {
    setcontract(e.target.value);
    handleMemberFormChange('contract_id', e.target.value);
  };

  const handleModelClose = () => {
    setIsRejectedModalOpen(false);
  };

  // updating contractdetail state depend dropdown select
  useEffect(() => {
    const contractdetails = contract_Count.filter(
      (param) => param?.id === contract
    );
    setcontractdetail(...contractdetails);
  }, [contract]);

  const handleSearch = (newData) => {
    queryClient.invalidateQueries({
      queryKey: ['organization_members/all'],
    });
    updateStateData('search', newData);
  };

  const addExcelMemberList = async (val) => {
    const response = await addExcelMappingMemberList(val, contract);
    return response;
  };

  const Onchange = (event, index) => {
    let tempExcelBoolVal = [];
    for (let i = 0; i < excelBoolVal.length; i++) {
      if (index === i) {
        tempExcelBoolVal = [
          ...tempExcelBoolVal,
          { ...excelBoolVal[i], excelColumn: event.target.value },
        ];
      } else {
        tempExcelBoolVal = [...tempExcelBoolVal, { ...excelBoolVal[i] }];
      }
    }
    setexcelBoolVal(tempExcelBoolVal);
  };

  const handleUpload = async () => {
    const mapedColumnData = excelBoolVal.map((data) => data.excelColumn);
    const filedColumnData = mappingColumnData.map((data) => data.field);
    const obj = {};
    for (let i = 0; i < mapedColumnData?.length; i++) {
      if (obj.hasOwnProperty(filedColumnData[i])) return;
      obj[filedColumnData[i]] = mapedColumnData[i];
    }
    const result = schemaDataMapper(excelData, obj);
    const name = result.map((data) => data.fullName);
    const mail = result.map((data) => data.email);
    const mobileNo = result.map((data) => data.phoneNumber);
    if (name[0]) {
      if (mail[0]) {
        if (mobileNo[0]) {
          if (mobileNo.filter((val) => val.length !== 10)) {
            if (contract) {
              if (contractdetail?.remaining_member_count >= result.length) {
                setExcelMappingMemberList(result);
                const response = await addExcelMemberList(true);

                queryClient.invalidateQueries({
                  queryKey: ['organization_members/all'],
                });

                setDownloadLink(response?.data?.rejected_data_url);

                if (response?.data?.rejected_data?.[0]?.length > 0) {
                  const resArr = [];
                  response?.data?.rejected_data?.[0]?.forEach(
                    (element, index) => {
                      const obj = {
                        id: index + 1,
                        ...element,
                      };
                      resArr.push(obj);
                    }
                  );

                  setRejectedData(resArr);
                  const column = [
                    {
                      field: 'first_name',
                      headerName: 'Name',
                      width: 200,
                    },
                    {
                      field: 'email_id',
                      headerName: 'Email',
                      width: 200,
                    },
                    {
                      field: 'mobile_no',
                      headerName: 'Mobile No',
                      width: 200,
                    },
                    {
                      field: 'organization_name',
                      headerName: 'Organization Name',
                      width: 200,
                    },
                  ];
                  setRejectedColumnData(column);
                  setIsModalOpen(false);
                  setIsRejectedModalOpen(true);
                } else {
                  await getAllOrganizationMembers(
                    options?.search,
                    options?.offset,
                    options?.limit
                  );
                  setIsModalOpen(false);
                }

                setCheckValid('');
              } else {
                setCheckValid(
                  `please select the contract higher then ${result.length}!`
                );
              }
            } else {
              setCheckValid('Please select the contract');
            }
          } else {
            setCheckValid(
              'Please Check the Mobile Number.The Mobile Numbers length Doesn &#180;t match'
            );
          }
        } else {
          setCheckValid('Please select the Mobile Number');
        }
      } else {
        setCheckValid('Please select the Email');
      }
    } else {
      setCheckValid('Please select the Full Name');
    }
  };

  const handleFileUpload = async () => {
    const file = inputRef.current.files[0];
    const fileType = file.name.split('.');
    getExcelData(fileType[1], file);
    inputRef.current.value = '';
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setCheckValid('');
  };
  const onClickFnc = () => {
    toast.success('Members Downloaded Successfully!');
  };

  useEffect(() => {
    getAllOrganizationMembers(options?.search, options?.offset, options?.limit);
    getGenderDetails();
  }, [options?.search]);

  useEffect(() => {
    if (excelData.length > 0) {
      getExcelColumnData(excelData[0]);
    }
  }, [excelData]);

  return (
    <Box>
      <Grid
        container
        py={2}
        sx={{
          display: 'flex',
          flexDirection: {
            lg: 'inherit',
            sm: 'column-reverse',
          },
        }}
      >
        <Grid item xs={12} lg={6}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography sx={members_top_container_Style.membersTextSx}>
                {`Members (${membersData?.length})`}
              </Typography>
            </Grid>
            <Grid item>
              <SearchField
                id="search"
                searchFieldStyle={members_top_container_Style.searchFieldSx}
                placeholder="Search Members by Name,Phone & Email"
                onSearch={options?.search}
                setOnSearch={handleSearch}
                startAdornment={
                  <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                    <BiSearch />
                  </IconButton>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid
            container
            sx={{ mb: 2, justifyContent: 'end' }}
            // columnSpacing={1.5}
            gap={1}
          >
            <Grid item>
              <Box sx={{ gap: 1.5 }} onClick={() => onClickFnc()}>
                <a
                  id="download"
                  href={download_url}
                  download
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="Outlined"
                    buttonStyle={members_top_container_Style.buttonSx}
                    startIcon={<MembersDownloadIcon />}
                  >
                    Download
                  </Button>
                </a>
              </Box>
            </Grid>
            <Grid item>
              <Box component="label" htmlFor='uploadInput'>
                <Box
                  sx={members_top_container_Style.uploadButtonSx}
                  id="upload"
                >
                  <Box pl={2} pt={1} pb={2}>
                    <MembersUploadIcon />
                  </Box>
                  <Box px={1} pb={2} pt={0.8}>
                    <Typography fontSize={14} fontWeight={500}>
                      Upload
                    </Typography>
                    <input
                      ref={inputRef}
                      hidden
                      accept=".csv,.xlsx"
                      type="file"
                      id="uploadInput"
                      onChange={handleFileUpload}
                    />
                  </Box>
                </Box>
              </Box>

              <UploadDialog
                handleUpload={handleUpload}
                handleSelect={handleSelect}
                loading={loading}
                Onchange={Onchange}
                excelBoolVal={excelBoolVal}
                isModalOpen={isModalOpen}
                handleClose={handleClose}
                checkValid={checkValid}
                contract={contract}
              />
            </Grid>
            <Grid item>
              <Button
                id="addMember"
                onClick={handleClick}
                buttonStyle={members_top_container_Style.addMemberSx}
              >
                Add Member
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* rejected data */}
      <DialogModal
        title="Rejected data"
        titleStyle={members_top_container_Style.titleSx}
        dialogRootStyle={members_top_container_Style.dialogSx}
        onCloseDialog
        content={
          <Box>
            <Table columns={rejectedColumnData} rows={rejectedData} />
          </Box>
        }
        actions={
          <Box display="flex" gap={2} width={334}>
            <a
              href={downloadLink}
              download
              style={{ textDecoration: 'none', width: 200 }}
            >
              <Button
                variant="outlined"
                buttonStyle={{ textTransform: 'capitalize' }}
              >
                Download Details
              </Button>
            </a>
            <Button
              buttonStyle={{ textTransform: 'capitalize', width: '100px' }}
              onClick={handleModelClose}
            >
              Close
            </Button>
          </Box>
        }
        isDialogOpened={isRejectedModalOpen}
        handleCloseDialog={handleModelClose}
      />
    </Box>
  );
}

MembersTopContainer.propTypes = {
  handleClick: PropTypes.func,
};
